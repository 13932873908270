<template>
  <div class="master-filiale-new-interface">
    <div class="body-box-rapport">
      <div class="table-rapport-style w-100-p">
        <div class="box-all-filter">
          <div class="box-label-champ mr-2">
            <div class="label-box-style-title">
              <span class="title-tabel">Facture Master Filiale</span>
            </div>
          </div>
          <filter-component
            v-if="checkPermission('GMFTHFDD')"
            label="Date début"
            classLabel="label-box-style w-63-px"
          >
            <template v-slot:body>
              <date-picker
                :clearable="true"
                v-model="date_debut"
                value-type="format"
                format="DD-MM-YYYY"
                :disabled-date="disabledStartDate"
                @change="handleFilter"
                type="date"
                required
                input-class="custom-date-picker-filter bg-white-color"
                class="custom-date-picker-calender-filter mt-2 mb-2"
              ></date-picker
            ></template>
          </filter-component>
          <filter-component
            label="Date fin"
            classLabel="label-box-style w-63-px"
            v-if="checkPermission('GMFTHFDF')"
          >
            <template v-slot:body>
              <date-picker
                :clearable="true"
                v-model="date_fin"
                :disabled-date="disabledEndDate"
                value-type="format"
                format="DD-MM-YYYY"
                @change="handleFilter"
                type="date"
                required
                input-class="custom-date-picker-filter bg-white-color"
                class="custom-date-picker-calender-filter mt-2 mb-2"
              ></date-picker
            ></template>
          </filter-component>
          <input-file-excel-num-dossier
            v-show="true"
            :value="nums"
            @searchWithNumDossier="searchWithMultipleFacture"
            :fetchFuntion="fecthFactureNumbersInExcelFile"
            :showCount="true"
          />
          <div class="error-message ml-2 mr-2">
            <div v-if="errorMaster2" class="error">
              <ul v-if="Array.isArray(errorMaster2)" class="mb-0">
                <li v-for="(e, index) in errorMaster2" :key="index">
                  {{ e }}
                </li>
              </ul>
              <div v-else>{{ errorMaster2 }}</div>
            </div>
          </div>
          <div
            v-if="loadingFactureMaster2"
            class="chargement chargement-loading-icon"
          >
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div class="box-end-position">
            <search
              v-if="checkPermission('GMFTHCS')"
              :value="searchValue"
              @changeSearchValue="changeSearchValue"
            />
            <GenerateFactureMaster
              v-if="checkPermission('GAPTHGFPPM')"
              :functionGenerate="generateFactureMastersObliges"
              @RefreshData="handleFilter"
            />
            <reglement
              class="mr-1refre"
              v-if="checkPermission('GMFTHAR') && computedCheckedRowsOneClient"
              :dataToUse="computedCheckedRowsOneClientFacture"
              interfaceModal="ReglementFactureRowLibre"
            />
            <!-- ReglementFactureRowMaster -->
            <!-- <add-facture-master2
              v-if="checkPermission('GMFTHANF')"
              @reloadData="reloadData"
            /> -->
            <!-- <facture-avoir-master2
              
            /> -->
            <generate-avoir
              v-if="
                computedCheckedRowsAvoir.length && checkPermission('GMFTHGFA')
              "
              @RefreshData="handleFilter"
              :selected="computedCheckedRowsAvoir"
              :functionGenerate="avoirGlobaleFactureMaster2"
              :famille="famille"
            />
            <transfert-facture-master2-to-libre
              v-if="
                computedCheckRowsToTransfertLibre &&
                  checkPermission('GMFTHTFATFL')
              "
              :dataToUse="computedCheckRowsToTransfertLibre"
              :indexsToRemove="computedGetIndex"
            />
            <validate-multiple-facture-master2
              v-if="
                computedCheckedRowsMultiple.length &&
                  checkPermission('GMFTHCMF')
              "
              @reloadData="reloadData"
              :dataToUse="computedCheckedRowsMultiple"
            />
            <!-- checkPermission('GMFTH2MMSF') ||  -->
            <!-- <validation-multiple-statut-client
            v-if="computedCheckedRows.length && checkPermission('GMFTHMSIPS')"
            class="mr-2"
            :dataToUse="computedCheckedRows"
            @reloadData="reloadData"
            :statutRequired="checkPermission('GMFTHMSPS') && false"
            :statutClientRequired="checkPermission('GMFTHMSIPS')"
          /> -->
            <b-button
              v-if="
                checkPermission('GMFTHFF') ||
                  checkPermission('GMFTHFC') ||
                  checkPermission('GMFTHFS') ||
                  checkPermission('GMFTHFSC')
              "
              size="sm"
              class="button-export-style ml-2 mr-2"
              title="Filter"
              id="popover-target-1"
            >
              <font-awesome-icon icon="filter" />
            </b-button>
            <b-popover
              triggers="focus"
              target="popover-target-1"
              placement="top"
              custom-class="my-custom-class-popover-filter"
            >
              <filter-component
                label="Société"
                classLabel="w-100-px"
                v-if="checkPermission('GMFTHFF')"
              >
                <template v-slot:body>
                  <multiselect
                    v-model="vendeur"
                    :multiple="true"
                    label="text"
                    @input="handleFilter"
                    :options="computedGetVendeurs"
                    track-by="value"
                    class="customSelectMultipleFilter mt-2 mb-2"
                    :showLabels="false"
                    placeholder="sélectionner"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                  >
                    <template slot="selection" slot-scope="{ values, isOpen }">
                      <span
                        :class="{
                          'multiselect__single showBlock': !isOpen,
                          'multiselect__single ': isOpen
                        }"
                        v-if="values.length == 1"
                        >{{ values.length }} Société</span
                      >
                      <span
                        :class="{
                          'multiselect__single showBlock': !isOpen,
                          'multiselect__single ': isOpen
                        }"
                        v-if="values.length > 1"
                        >{{ values.length }} Sociétés</span
                      >
                    </template>
                  </multiselect></template
                >
              </filter-component>
              <filter-component
                label="Client"
                classLabel="w-100-px"
                v-if="checkPermission('GMFTHFC')"
              >
                <template v-slot:body>
                  <multiselect
                    v-model="acheteur"
                    :multiple="true"
                    label="text"
                    @input="handleFilter"
                    :options="computedGetAcheteurs"
                    track-by="value"
                    class="customSelectMultipleFilter mt-2 mb-2"
                    :showLabels="false"
                    placeholder="sélectionner"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                  >
                    <template slot="selection" slot-scope="{ values, isOpen }">
                      <span
                        :class="{
                          'multiselect__single showBlock': !isOpen,
                          'multiselect__single ': isOpen
                        }"
                        v-if="values.length == 1"
                        >{{ values.length }} Client</span
                      >
                      <span
                        :class="{
                          'multiselect__single showBlock': !isOpen,
                          'multiselect__single ': isOpen
                        }"
                        v-if="values.length > 1"
                        >{{ values.length }} Clients</span
                      >
                    </template>
                  </multiselect></template
                >
              </filter-component>
              <filter-component
                label="Statut"
                classLabel="w-100-px
"
                v-if="checkPermission('GMFTHFS')"
              >
                <template v-slot:body>
                  <multiselect
                    v-model="statut"
                    :options="computedListStatutFilter"
                    @input="handleFilter"
                    :multiple="true"
                    label="full_name"
                    track-by="value"
                    class="customSelectMultipleFilter libres mt-2 mb-2"
                    :showLabels="false"
                    placeholder="sélectionner"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :preselect-first="false"
                  >
                    <template slot="selection" slot-scope="{ values, isOpen }">
                      <span
                        :class="{
                          'multiselect__single showBlock': !isOpen,
                          'multiselect__single ': isOpen
                        }"
                        v-if="values.length == 1"
                        >{{ values.length }} Statut</span
                      >
                      <span
                        :class="{
                          'multiselect__single showBlock': !isOpen,
                          'multiselect__single ': isOpen
                        }"
                        v-if="values.length > 1"
                        >{{ values.length }} Statuts</span
                      >
                    </template>
                  </multiselect></template
                >
              </filter-component>
              <filter-component
                label="Statut client"
                classLabel="w-100-px
"
                v-if="checkPermission('GMFTHFSC')"
              >
                <template v-slot:body>
                  <multiselect
                    v-model="statut_client"
                    :options="computedListStatutClientFilter"
                    @input="handleFilter"
                    :multiple="true"
                    label="full_name"
                    track-by="value"
                    class="customSelectMultipleFilter libres mt-2 mb-2"
                    :showLabels="false"
                    placeholder="sélectionner"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :preselect-first="false"
                  >
                    <template slot="selection" slot-scope="{ values, isOpen }">
                      <span
                        :class="{
                          'multiselect__single showBlock': !isOpen,
                          'multiselect__single ': isOpen
                        }"
                        v-if="values.length == 1"
                        >{{ values.length }} Statut</span
                      >
                      <span
                        :class="{
                          'multiselect__single showBlock': !isOpen,
                          'multiselect__single ': isOpen
                        }"
                        v-if="values.length > 1"
                        >{{ values.length }} Statuts</span
                      >
                    </template>
                  </multiselect></template
                >
              </filter-component>
            </b-popover>
            <export-dynamic
              v-if="checkPermission('GMFTHEXLS') || checkPermission('GMFTHEZP')"
              :filter="ComputedFilter"
              :selected="computedCheckRowsExport"
              :exportXls="exportFactureLibre"
              :exportZip="downloadZIP"
              :zipRequired="checkPermission('GMFTHEZP')"
              :xlsRequired="checkPermission('GMFTHEXLS')"
              :famille="famille"
            ></export-dynamic>
            <div
              v-if="getLoadingMailMasterFiliale"
              class="chargement chargement-loading-icon"
            >
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div
              v-if="
                !getLoadingMailMasterFiliale &&
                  computedDataToSend.length > 0 &&
                  checkPermission('GMFTHEM')
              "
            >
              <sendMail
                :dataToUse="computedDataToSend"
                @eventMailSendRefreshTable="handleFilter()"
              />
            </div>
          </div>
        </div>
        <div>
          <div
            v-if="date_debut != null || date_fin != null || searchValue != null"
            class="block-filter-style"
          >
            <span class="phrase-style">Les filtres choisie sont: </span>
            <span class="item-filter-style" v-if="date_debut != null"
              >Date début : {{ date_debut | formateDate }}</span
            >
            <span class="item-filter-style" v-if="date_fin != null"
              >Date fin : {{ date_fin | formateDate }}</span
            >
            <span class="item-filter-style" v-if="searchValue != null"
              >Recherche : {{ searchValue }}</span
            >
          </div>
        </div>
        <div class="chip-filters-simulation">
          <div class="block-filter" v-if="vendeur.length">
            <span class="title-block-chip">Société : </span>
            <vs-chips
              color="rgb(145, 32, 159)"
              v-model="vendeur"
              @input="handleFilter"
            >
              <vs-chip
                :key="v.text + 'societe'"
                @click="remove(v, 'vendeur', 'handleFilter')"
                v-for="v in vendeur"
                closable
              >
                {{ v.text }}
              </vs-chip>
            </vs-chips>
          </div>
          <div class="block-filter" v-if="acheteur.length">
            <span class="title-block-chip">Client : </span>
            <vs-chips
              color="rgb(145, 32, 159)"
              v-model="acheteur"
              @input="handleFilter"
            >
              <vs-chip
                :key="v.text + 'societe'"
                @click="remove(v, 'acheteur', 'handleFilter')"
                v-for="v in acheteur"
                closable
              >
                {{ v.text }}
              </vs-chip>
            </vs-chips>
          </div>
          <div class="block-filter" v-if="statut.length">
            <span class="title-block-chip">Statut : </span>
            <vs-chips
              color="rgb(145, 32, 159)"
              placeholder="New Element"
              v-model="statut"
              @input="handleFilter"
            >
              <vs-chip
                :key="s.full_name + 'staut'"
                @click="remove(s, 'statut', 'handleFilter')"
                v-for="s in statut"
                closable
              >
                {{ s.full_name }}
              </vs-chip>
            </vs-chips>
          </div>
          <div class="block-filter" v-if="statut_client.length">
            <span class="title-block-chip">Statut client : </span>
            <vs-chips
              color="rgb(145, 32, 159)"
              placeholder="New Element"
              v-model="statut_client"
              @input="handleFilter"
            >
              <vs-chip
                :key="s.full_name + 'client'"
                @click="remove(s, 'statut_client', 'handleFilter')"
                v-for="s in statut_client"
                closable
              >
                {{ s.full_name }}
              </vs-chip>
            </vs-chips>
          </div>
        </div>

        <b-table
          :style="sizeTable"
          show-empty
          id="my-table"
          class="custom-table-style table-factures-mensuel-style table-header"
          :items="factureMaster2"
          :fields="computedFields"
          bordered
          sticky-header
          hover
          responsive
          head-variant="light"
          tbody-tr-class="ligneNormale"
          empty-text="Il n'y a aucun enregistrement à afficher"
        >
          <template v-slot:head()="data">
            <div>
              <span v-if="data.field.key != 'check_all'">
                {{ data.field.label }}</span
              >
              <div v-else>
                <b-form-checkbox
                  v-model="checkAll"
                  @change="checkAllFunction"
                  class="check-th"
                  :value="true"
                  :unchecked-value="false"
                >
                </b-form-checkbox>
              </div>
            </div>
          </template>
          <template v-slot:cell(check_all)="data">
            <b-form-checkbox
              v-model="data.item.check"
              @change="checkAllVerif(data.item)"
              class="check-th"
              :value="true"
              :unchecked-value="false"
            >
            </b-form-checkbox>
          </template>

          <template v-slot:cell(details)="data">
            <button
              class="btn btn-anomalie"
              v-if="data.item.factures.length > 0"
              @click="data.toggleDetails"
            >
              <font-awesome-icon
                class="icon-plus"
                icon="plus-square"
                v-if="data.detailsShowing == false"
              />
              <font-awesome-icon
                class="icon-plus"
                icon="minus-square"
                v-if="data.detailsShowing == true"
              />
            </button>
          </template>
          <template v-slot:cell(Vendeur)="data">
            {{ data.item.name }}
          </template>
          <template v-slot:cell(Acheteur)="">
            {{ '-' }}
          </template>
          <template v-slot:cell(sous_objet)="">
            {{ '-' }}
          </template>
          <template v-slot:cell(num)="data">
            {{ data.item.vendeur ? data.item.vendeur : '-' }}
          </template>
          <template v-slot:cell(num_avoir)="">
            {{ '-' }}
          </template>
          <template v-slot:cell(date)="data">
            {{ data.item.vendeur ? data.item.vendeur : '-' }}
          </template>
          <template v-slot:cell(total_net_ht)="data">
            {{ data.item.total_net_ht }} €
          </template>
          <template v-slot:cell(total_tva)="data">
            {{ data.item.total_tva }} €
          </template>
          <template v-slot:cell(total_ttc)="data">
            {{ data.item.total_ttc }} €
          </template>
          <template v-slot:cell(etat)="data">
            {{ data.item.vendeur ? data.item.vendeur : '-' }}
          </template>
          <template v-slot:cell(statut_client)="data">
            {{ data.item.vendeur ? data.item.vendeur : '-' }}
          </template>
          <template v-slot:cell(date_paiement)=""> - </template>
          <template v-slot:cell(reste)=""> - </template>
          <template v-slot:cell(reglement)=""> - </template>
          <template v-slot:cell(historique)=""> - </template>
          <template #row-details="data">
            <span
              v-for="(item, index) in data.item.factures"
              :key="data.item.id + 'mensuel' + index"
            >
              <template>
                <span class="align-td-item background-week">
                  <td class="item-details-style col-check-obligee">
                    <b-form-checkbox
                      v-model="item.check"
                      class="check-th"
                      :value="true"
                      :unchecked-value="false"
                    >
                    </b-form-checkbox>
                  </td>
                  <td class="item-details-style col-plus-obligee">
                    <span> </span>
                  </td>
                  <td
                    class="item-details-style"
                    v-if="checkPermission('GMFTHCSO')"
                  >
                    {{ data.item.name }}
                  </td>
                  <td
                    class="item-details-style"
                    v-if="checkPermission('GMFTHCCL')"
                  >
                    {{ item.acheteur }}
                  </td>

                  <td
                    class="item-details-style"
                    v-if="checkPermission('GMFTHCDEPT')"
                  >
                    {{ item.sous_objet != null ? item.sous_objet : '-' }}
                  </td>
                  <td
                    class="item-details-style"
                    v-if="checkPermission('GMFTHCNF')"
                  >
                    {{ item.num }}
                    <font-awesome-icon
                      v-if="checkPermission('GMFTHCNF')"
                      icon="eye"
                      @click="handleShowFacture(item.id)"
                    />
                  </td>
                  <td
                    class="item-details-style"
                    v-if="checkPermission('GMFTHCNFA')"
                  >
                    <div class="d-flex justify-content-between ">
                      <font-awesome-icon
                        :id="`popover-1-avoir-${data.item.name}-${index}`"
                        v-show="
                          item.FactureAvoir &&
                            item.FactureAvoir.length != 0 &&
                            item.type == 'Facture'
                        "
                        icon="info"
                        class="
                          info-icon-commercial-lot
                          content-tooltip-style
                          w-25
                        "
                      />
                      <span
                        v-if="
                          item.FactureAvoir.length && item.type == 'Facture'
                        "
                        class="mr-2"
                        >{{
                          getSumAvoirFacturePartial(item.FactureAvoir)
                        }}
                        €</span
                      >
                      <avoir-facture
                        v-if="
                          item.type_facture != 'Facture proforma' &&
                            item.etat != 'Annulée' &&
                            item.type == 'Facture' &&
                            item.client_id == 20
                        "
                        :item="item"
                        :permission="checkPermission('GMFTHGDAP')"
                        :index="data.item.name + index"
                      />
                    </div>

                    <b-popover
                      :target="`popover-1-avoir-${data.item.name}-${index}`"
                      :triggers="computedPopoverTriggers"
                      custom-class="avoir-tooltip custom-z-index-popover"
                    >
                      <div v-for="(facture, i) in item.FactureAvoir" :key="i">
                        <div class="d-flex align-items-center">
                          <div class="d-inline-block">
                            {{ facture.num }} , {{ facture.total_ttc }} €

                            <font-awesome-icon
                              v-if="checkPermission('GMFTHEFAP')"
                              icon="arrow-alt-circle-down"
                              class="
                                  icon-style-color
                                  mr-1
                                  button-export-style-avoir
                                "
                              @click="
                                handleClickDownloadFactureAvoir(facture.id)
                              "
                            />
                          </div>
                        </div>
                      </div>
                    </b-popover>
                  </td>
                  <td
                    class="item-details-style"
                    v-if="checkPermission('GMFTHCDF')"
                  >
                    {{ item.date_creation | formateDate }}
                  </td>
                  <td
                    class="item-details-style"
                    v-if="checkPermission('GMFTHCMOHT')"
                  >
                    <EditableInput
                      :editable="
                        item.type == 'Facture proforma' &&
                          checkPermission('GMFTHMMF') &&
                          item.modifiable == true &&
                          false
                      "
                      champName="total_net_ht"
                      :item="item"
                      :value="item.total_net_ht"
                      type="text"
                      editableType="input"
                      :updateFunction="updateMontantHtFactureMaster2"
                      :defaultColor="true"
                      :uniteMontant="euro"
                    />
                  </td>
                  <td
                    class="item-details-style"
                    v-if="checkPermission('GMFTHCTVA')"
                  >
                    {{ item.total_tva }} €
                  </td>
                  <td
                    class="item-details-style"
                    v-if="checkPermission('GMFTHCMO')"
                  >
                    <templateFraisMaster2
                      :item="item"
                      :project="item"
                      :componentName="$options.name"
                      :editable="
                        item.etat == 'Payé' ||
                        item.etat == 'Annulée' ||
                        item.modifiable == false
                          ? false
                          : checkPermission('GMFTHVF')
                          ? true
                          : false
                      "
                      :download="item.etat == 'Annulée' ? false : item.id"
                    ></templateFraisMaster2>
                  </td>

                  <td
                    class="item-details-style"
                    v-if="checkPermission('GMFTHCRAP')"
                  >
                    {{ item.reste }} €
                  </td>
                  <td
                    class="item-details-style"
                    v-if="checkPermission('GMFTHCCSF')"
                  >
                    <EditableInput
                      :editable="
                        checkPermission('GMFTHMSPF') &&
                          item.etat != 'Payé' &&
                          item.etat != 'Annulée' &&
                          item.modifiable == true &&
                          item.type == 'Facture' &&
                          false
                      "
                      champName="etat"
                      editableType="select"
                      :optionsSelect="computedListStatut"
                      :item="item"
                      :value="item.etat"
                      :updateFunction="updateStatutFactureMaster2"
                      label="value"
                      :positionSelect="true"
                      formate="regieFacture"
                      :showIconEdit="true"
                    />
                  </td>

                  <td
                    class="item-details-style"
                    v-if="checkPermission('GMFTHCSPI')"
                  >
                    <div
                      v-if="
                        (item.type == 'facture' || item.type == 'Facture') &&
                          item.etat != 'Annulée' &&
                          item.etat != 'Payé' &&
                          item.statut_client != 'Payé' &&
                          checkPermission('GMFTHMSIPF') &&
                          item.modifiable == true &&
                          false
                      "
                    >
                      <EditableInput
                        champName="statut_client"
                        editableType="select"
                        :optionsSelect="computedListStatutClient"
                        :item="item"
                        :value="item.statut_client"
                        :updateFunction="updateStatutClientFactureMaster2"
                        label="value"
                        :positionSelect="true"
                        formate="regieFacture"
                        :showIconEdit="true"
                      />
                    </div>
                    <div v-else>
                      {{ item.statut_client ? item.statut_client : '-' }}
                    </div>
                  </td>
                  <td
                    class="item-details-style"
                    v-if="checkPermission('GMFTHCDP')"
                  >
                    {{ item.date_paiement }}
                  </td>
                  <td
                    class="item-details-style"
                    v-if="checkPermission('GMFTHCRG')"
                  >
                    {{ item.montant_regle }} €
                    <font-awesome-icon
                      v-show="item.reglements.length"
                      :id="`popover-1-${index}`"
                      icon="info"
                      class="
                        info-icon-commercial-lot
                        content-tooltip-style
                        w-25
                      "
                    />

                    <b-popover
                      :target="`popover-1-${index}`"
                      :triggers="computedPopoverTriggers"
                      :custom-class="'avoir-tooltip ' + stylePopover"
                    >
                      <b-table-simple class="table-fiche">
                        <b-tbody>
                          <b-tr class="title-ligne">
                            <b-th class="newcolor">#</b-th>
                            <b-th class="newcolor">Montant reglé</b-th>
                            <b-th class="newcolor">Date de paiement</b-th>
                            <b-th class="newcolor">Type de paiement</b-th>
                            <b-th
                              class="newcolor"
                              v-if="checkPermission('GMFTHUR')"
                            ></b-th>
                          </b-tr>
                          <template v-for="(reglement, i) in item.reglements">
                            <b-tr :key="'reglement' + i">
                              <b-td class="newcolor"
                                >{{ reglement.payment_id }}
                              </b-td>
                              <b-td class="newcolor"
                                >{{ reglement.amount }} €
                              </b-td>
                              <b-td class="newcolor">{{
                                formateDateGetters(reglement.payment_date)
                              }}</b-td>
                              <b-td class="newcolor">{{
                                reglement.payment_type
                              }}</b-td>
                              <b-td
                                class="newcolor"
                                v-if="checkPermission('GMFTHUR')"
                                ><update-reglement-modal
                                  :item="reglement"
                                  dataInterface="master"
                                  @openModal="triggersPop = 'click'"
                                  @closeModal="triggersPop = 'hover'"
                              /></b-td>
                            </b-tr>
                          </template>
                        </b-tbody>
                      </b-table-simple>
                    </b-popover>
                  </td>
                  <td
                    class="item-details-style"
                    v-if="checkPermission('GMFTHCH')"
                  >
                    <Historique
                      :awesome="true"
                      :index="item.id"
                      :permission="checkPermission('GMFTHCH')"
                      :dataToUse="item.historique"
                    />
                  </td>
                </span>
              </template>
            </span>
          </template>
        </b-table>

        <b-modal
          ref="visualisationFactureModal"
          id="visualisationFactureModal"
          title="Ajouter une année"
          :hide-footer="true"
          :hide-header="true"
          modal-class="cutsom-modal-bootstrap modal-dialog-visualisation"
        >
          <div class="hader mb-2">
            <div class="titleSetting">Afficher Facture</div>
            <div
              class="iconClose"
              @click.prevent="hideModal('visualisationFactureModal')"
            >
              <font-awesome-icon icon="times" />
            </div>
          </div>
          <Card>
            <template v-slot:body>
              <iframe
                height="750"
                width="700"
                :src="factureToShow + '#toolbar=0'"
                scrolling="no"
              ></iframe>
            </template>
          </Card>
        </b-modal>
        <div class="footer-style mt-2">
          <b-pagination
            v-model="page"
            :total-rows="TotalRowsMaster2"
            :per-page="perPage"
            aria-controls="my-table"
            pills
            align="center"
            size="sm"
            @change="pagination"
            class="pagination-style"
          ></b-pagination>

          <div class="per-page-element-style">
            <div class="box-label-champ">
              <div class="label-box-style">
                <span class="title-tabel">Eléments par page</span>
              </div>
            </div>
            <b-form-select
              v-model="perPage"
              :options="perPageList"
              @change="changePerPage"
              class="b-form-select-new-style bg-select"
            ></b-form-select>
          </div>
          <div
            v-if="
              computedTotal &&
                (computedTotal.net_ht > 0 ||
                  computedTotal.ttc > 0 ||
                  computedTotal.someApaye > 0) &&
                checkPermission('GMFTHABT')
            "
            class="style-somme-th-ttc"
          >
            <p class="m-2">
              Total HT:
              {{   computedTotal.net_ht
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                .replaceAll(',', ' '), }}
              € {{ '   ' }}Total TTC:

              {{
                computedTotal.ttc
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  .replaceAll(',', ' ')
              }}
              € Total à payer :
              {{
                computedTotal.someApaye
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  .replaceAll(',', ' ')
              }}
              €
            </p>
          </div>
        </div>
      </div>
      <!-- <div v-if="computedtotalht > 0" class="style-somme-th-ttc">
        <p class="m-2">
          Total ht:
          {{
            computedtotalht
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              .replaceAll(',', ' ')
          }}€ {{ '   ' }}Total ttc:

          {{
            (computedtotalht * 1.2)
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              .replaceAll(',', ' ')
          }}€
        </p>
      </div> -->
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import EditableInput from '../../../component/EditableInput.vue';
import ExportDynamic from '@/views/ThProject/frais/fraisDynamic/component/exportDynamic.vue';
import filterComponent from '../component/filterComponent.vue';
import ValidateMultipleFactureMaster2 from './component/ValidateMultipleFactureMasterFiliale2.vue';
// import ValidationMultipleStatutClient from './component/ValidationMultipleStatutClient.vue';

export default {
  name: 'Gestion-Facture-Master-2',
  data() {
    return {
      triggersPop: 'hover',
      date_debut: null,
      date_fin: null,
      page: 1,
      perPage: 20,
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 }
      ],
      famille: 'master',
      searchValue: null,
      checkAll: false,
      column: 'id',
      order: 'DESC',
      factureToShow: null,
      vendeur: [],
      acheteur: [],
      statut: [
        { value: 'A payer', full_name: 'A payer' },
        {
          value: 'Créé',
          full_name: 'Créé'
        },
        { value: 'Payé en partie', full_name: 'Payé en partie' }
      ],
      statut_client: [],
      euro: ' €',
      nums: null
    };
  },
  filters: {
    formateDate: value => {
      if (value == '' || value == null) {
        return '-';
      } else if (moment(value, 'DD-MM-YYYY', true).isValid()) {
        return value;
      } else {
        return moment(new Date(value)).format('DD-MM-YYYY');
      }
    }
  },
  computed: {
    ...mapGetters([
      'loadingFactureMaster2',
      'vendeurMaster2',
      'acheteurMaster2',
      'factureMaster2',
      'checkPermission',
      'errorMaster2',
      'getLoadingMail',
      'isResponsableFiliale',
      'TotalRowsMaster2',
      'getLoadingMailMasterFiliale',
      'formateDateGetters',
      'getUserData',
      'getSumAvoirFacturePartial'
    ]),
    computedPopoverTriggers() {
      return this.triggersPop;
    },
    stylePopover() {
      if (this.computedPopoverTriggers == 'click') {
        return 'displayed-none';
      }
      return '';
    },
    computedCheckedRowsOneClient() {
      let tableReglement = [];
      let tableReglementCheck = [];
      let filiale_list = this.getUserData.filiales.map(item => item.id);

      if (this.factureMaster2.length) {
        this.factureMaster2.forEach(element => {
          element.factures.forEach(facture => {
            if (facture.check == true) {
              tableReglementCheck.push(facture);
            }
            if (
              facture.check == true &&
              facture.reste != '0' &&
              facture.type != 'Facture proforma' &&
              (facture.etat == 'A payer' || facture.etat == 'Payé en partie') &&
              facture.belongsTo == true
            ) {
              if (tableReglement.length > 0) {
                if (filiale_list.includes(tableReglement[0].client_id)) {
                  if (
                    facture.vendeur_id == tableReglement[0].vendeur_id &&
                    filiale_list.includes(facture.client_id) &&
                    facture.client_id == tableReglement[0].client_id
                  ) {
                    tableReglement.push(facture);
                  }
                } else {
                  if (
                    facture.client_id == tableReglement[0].client_id &&
                    facture.vendeur_id == tableReglement[0].vendeur_id
                  ) {
                    tableReglement.push(facture);
                  }
                }
              } else {
                tableReglement.push(facture);
              }
            }
          });
        });
        return tableReglement.length == tableReglementCheck.length &&
          tableReglement.length > 0
          ? true
          : false;
      }
      return false;
    },
    computedCheckedRowsOneClientFacture() {
      let tableReglement = [];
      if (this.factureMaster2.length) {
        let filiale_list = this.getUserData.filiales.map(item => item.id);

        this.factureMaster2.forEach(element => {
          element.factures.forEach(facture => {
            if (
              facture.check == true &&
              facture.reste != '0' &&
              facture.type != 'Facture proforma' &&
              (facture.etat == 'A payer' || facture.etat == 'Payé en partie') &&
              facture.belongsTo == true
            ) {
              if (tableReglement.length > 0) {
                if (filiale_list.includes(tableReglement[0].client_id)) {
                  if (
                    facture.vendeur_id == tableReglement[0].vendeur_id &&
                    filiale_list.includes(facture.client_id)
                  ) {
                    tableReglement.push(facture);
                  }
                } else {
                  if (facture.client_id == tableReglement[0].client_id) {
                    tableReglement.push(facture);
                  }
                }
              } else {
                tableReglement.push(facture);
              }
            }
          });
        });
      }
      return tableReglement;
    },
    computedCheckChipFilter() {
      if (
        this.vendeur.length > 0 ||
        this.acheteur.length > 0 ||
        this.statut.length > 0 ||
        this.statut_client.length > 0
      ) {
        return true;
      }
      return false;
    },
    sizeTable() {
      let heigthBlock = 0;
      if (this.computedCheckChipFilter == true) {
        let table2 = [];
        let table = document.getElementsByClassName('con-chips');
        table.forEach(element => {
          table2.push(element.clientHeight);
        });
        heigthBlock = table2.length
          ? Math.max(...table2)
          : this.computedCheckChipFilter == true
          ? 40
          : 0;
      }
      let hei = 257 + heigthBlock;
      return (
        'max-height : calc(100vh - ' +
        `${hei}` +
        'px)!important ; height: calc(100vh - ' +
        `${hei}` +
        'px)!important;'
      );
    },
    sizeBlockTable() {
      let heigthBlock = 0;

      if (this.computedCheckChipFilter == true) {
        let table2 = [];
        let table = document.getElementsByClassName('con-chips');
        if (table.length) {
          for (let i = 0; i < table.length; i++) {
            table2.push(table[i].clientHeight);
          }
        }

        heigthBlock = table2.length
          ? Math.max(...table2)
          : this.computedCheckChipFilter == true
          ? 40
          : 0;
      }
      let hei = 206 + heigthBlock;
      return (
        'max-height : calc(100vh - ' +
        `${hei}` +
        'px)!important ; height: calc(100vh - ' +
        `${hei}` +
        'px)!important;'
      );
    },
    computedTotal() {
      let someHt = 0;
      let someTtc = 0;
      let someApaye = 0;
      for (let i = 0; i < this.computedCheckedRows.length; i++) {
        for (let j = 0; j < this.computedCheckedRows[i].factures.length; j++) {
          if (
            this.computedCheckedRows[i].factures[j].check == true &&
            this.computedCheckedRows[i].factures[j].avoir_id === null
          ) {
            someHt =
              someHt +
                Math.round(
                  parseFloat(
                    this.computedCheckedRows[i].factures[j].net_ht +
                      ' '.replace(' ', '').replace(',', '.')
                  ) * 100
                ) /
                  100 || 0;
            someTtc =
              someTtc +
                Math.round(
                  parseFloat(
                    this.computedCheckedRows[i].factures[j].ttc +
                      ' '.replace(' ', '').replace(',', '.')
                  ) * 100
                ) /
                  100 || 0;
            if (
              this.computedCheckedRows[i].factures[j].type == 'Facture' &&
              (this.computedCheckedRows[i].factures[j].etat == 'A payer' ||
                this.computedCheckedRows[i].factures[j].etat ==
                  'Payé en partie' ||
                this.computedCheckedRows[i].factures[j].etat == 'Payé')
            ) {
              someApaye =
                someApaye +
                  Math.round(
                    parseFloat(
                      this.computedCheckedRows[i].factures[j].reste +
                        '' +
                        ' '.replace(' ', '').replace(',', '.')
                    ) * 100
                  ) /
                    100 || 0;
            }
          }
        }
      }
      return {
        net_ht: Math.round(someHt * 100) / 100,
        ttc: Math.round(someTtc * 100) / 100,
        someApaye: Math.round(someApaye * 100) / 100
      };
    },
    computedGetVendeurs() {
      let vendeurNames = this.vendeurMaster2.map(item => {
        return { value: item.id, text: item.name };
      });
      return vendeurNames;
    },
    computedGetAcheteurs() {
      let acheteurNames = this.acheteurMaster2.map(item => {
        return { value: item.id, text: item.name };
      });
      return acheteurNames;
    },
    computedCheckRowsExport() {
      let selected = [];
      this.factureMaster2.forEach(element => {
        element.factures.forEach(facture => {
          if (facture.check == true) {
            selected.push(facture.id);
          }
        });
      });
      return selected;
    },
    computedGetIndex() {
      let arrayOfIndex = [];
      for (let i = 0; i < this.computedCheckedRows.length; i++) {
        for (let j = 0; j < this.computedCheckedRows[i].factures.length; j++) {
          if (
            this.computedCheckedRows[i].factures[j].check == true &&
            this.computedCheckedRows[i].factures[j].avoir_id !== null
          ) {
            arrayOfIndex.push({
              indexOfChild: this.computedCheckedRows[i].factures[j].id,
              indexOfFather: this.computedCheckedRows[i].name
            });
          }
        }
      }
      return arrayOfIndex;
    },
    computedCheckRowsToTransfertLibre() {
      let selected = [];
      this.factureMaster2.forEach(element => {
        element.factures.forEach(facture => {
          if (
            facture.check == true &&
            facture.avoir_id !== null &&
            facture.etat == 'Annulée' &&
            facture.modifiable == true
          ) {
            selected.push(facture.id);
          }
        });
      });
      return selected;
    },
    computedDataToSend() {
      let selected = [];
      this.factureMaster2.forEach(element => {
        element.factures.forEach(facture => {
          if (
            facture.check == true &&
            facture.etat != 'Créé' &&
            facture.modifiable == true &&
            facture.type != 'Facture proforma'
          ) {
            selected.push(facture);
          }
        });
      });
      return selected;
    },
    computedCheckedRows() {
      let tableF = this.factureMaster2?.filter(f => {
        return f.factures.some(e => e.check == true);
      });
      return tableF;
    },
    computedCheckedRowsMultiple() {
      let tableF = this.factureMaster2?.filter(f => {
        return f.factures.some(
          e =>
            e.check == true &&
            e.type == 'Facture proforma' &&
            e.modifiable == true
        );
      });
      return tableF;
    },
    computedCheckedRowsAvoir() {
      let tableF = this.factureMaster2.filter(f => {
        return f.factures.some(
          e =>
            e.check == true &&
            e.type == 'Facture' &&
            e.avoir_id == null &&
            e.etat != 'Annulée' &&
            e.modifiable == true &&
            e.FactureAvoir.length == 0
        );
      });
      return tableF;
    },
    ComputedFilter() {
      return {
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        acheteur: this.acheteur,
        vendeur: this.vendeur,
        etat: this.statut,
        statut_client: this.statut_client,
        col: 'id'
      };
    },
    computedCheckRows() {
      let selected = [];
      this.factureMaster2?.map(element => {
        if (element.check == true) {
          selected.push(element.id);
        }
      });
      return selected;
    },
    computedListStatut() {
      return [
        { value: 'A payer', full_name: 'Facture à payer' },
        { value: 'Payé', full_name: 'Facture payée' }
      ];
    },
    computedListStatutFilter() {
      return [
        { value: 'A payer', full_name: 'Facture à payer' },
        { value: 'Payé', full_name: 'Facture payée' },
        { value: 'Crée', full_name: 'Facture crée' },
        { value: 'Annulée', full_name: 'Facture Annulée' },
        { value: 'Payé en partie', full_name: 'Payé en partie' }
      ];
    },
    computedListStatutClientFilter() {
      return [
        { value: 'A payer', full_name: 'Facture à payer' },
        { value: 'Payé', full_name: 'Facture payée' },
        { value: 'Crée', full_name: 'Facture crée' },
        { value: 'Annulée', full_name: 'Facture Annulée' },
        { value: 'Payé en partie', full_name: 'Payé en partie' }
      ];
    },
    computedListStatutClient() {
      return [
        { value: 'A payer', full_name: 'à payer' },
        { value: 'Payé', full_name: 'Payée' }
      ];
    },
    computedFields() {
      let fields = [
        {
          key: 'check_all',
          label: '',
          thClass: 'th-check-obligee',
          tdClass: 'col-check-obligee',
          show: true
        },
        {
          key: 'details',
          label: '',
          thClass: 'th-plus-obligee',
          tdClass: 'col-plus-obligee',
          show: true
        },
        {
          key: 'Vendeur',
          label: 'Societé',
          show: this.checkPermission('GMFTHCSO')
        },
        {
          key: 'Acheteur',
          label: 'Client',
          show: this.checkPermission('GMFTHCCL')
        },
        {
          key: 'sous_objet',
          label: 'Dépôt',
          show: this.checkPermission('GMFTHCDEPT')
        },
        {
          key: 'num',
          label: 'N° Facture',
          show: this.checkPermission('GMFTHCNF')
        },
        {
          key: 'num_avoir',
          label: 'Avoir(s)',
          show: this.checkPermission('GMFTHCNFA')
        },
        {
          key: 'date',
          label: 'Date',
          show: this.checkPermission('GMFTHCDF')
        },
        {
          key: 'total_net_ht',
          label: 'Montant HT',
          show: this.checkPermission('GMFTHCMOHT')
        },
        {
          key: 'total_tva',
          label: 'TVA',
          show: this.checkPermission('GMFTHCTVA')
        },
        {
          key: 'total_ttc',
          label: 'Montant TTC ',
          show: this.checkPermission('GMFTHCMO')
        },
        {
          key: 'reste',
          label: 'Reste à payer',
          show: this.checkPermission('GMFTHCRAP')
        },
        {
          key: 'etat',
          label: 'Statut',
          show: this.checkPermission('GMFTHCCSF')
        },
        {
          key: 'statut_client',
          label: 'Statut client',
          show: this.checkPermission('GMFTHCSPI')
        },
        {
          key: 'date_paiement',
          label: 'Date de paiement',
          show: this.checkPermission('GMFTHCDP')
        },
        {
          key: 'reglement',
          label: 'Reglement',
          show: this.checkPermission('GMFTHCRG')
        },
        {
          key: 'historique',
          label: 'Historique',
          show: this.checkPermission('GMFTHCH')
        }
      ];
      return fields.filter(item => item.show === true);
    }
  },
  methods: {
    ...mapActions([
      'getSousObjets',
      'getVendeurAcheteurMaster2',
      'getFacturesMaster2',
      'updateStatutFactureMaster2',
      'deleteFactureComptaRhActions',
      'updateStatutClientFactureMaster2',
      'generateAvoir',
      'exportFactureLibre',
      'downloadZIP',
      'downloadPDF',
      'displayFact',
      'updateMontantHtFactureMaster2',
      'exportPdfFactureAvoirDynamic',
      'generateFactureMastersObliges',
      'getSettingFilialeTh',
      'avoirGlobaleFactureMaster2',
      'fecthFactureNumbersInExcelFile'
    ]),
    searchWithMultipleFacture(value) {
      if (this.nums != value) {
        this.nums = value;
        this.handleFilter();
      }
    },
    handleClickDownloadFactureAvoir(id) {
      this.exportPdfFactureAvoirDynamic({ id: id });
    },
    remove(item, filter, fct) {
      this[filter].splice(this[filter].indexOf(item), 1);
      this[fct]();
    },
    checkAllFunction() {
      this.factureMaster2.map(item => {
        item.check = this.checkAll;
        item.factures.map(facture => {
          facture.check = this.checkAll;
        });
      });
    },
    checkAllVerif(item) {
      if (item.check == false && this.checkAll == true) {
        this.checkAll = false;
      }
      item.factures.map(facture => {
        facture.check = item.check;
      });
    },
    disabledStartDate(date) {
      return (
        this.date_fin &&
        moment(date, 'YYYY-MM-DD') >=
          new Date(moment(this.date_fin, 'DD-MM-YYYY').format('YYYY-MM-DD'))
      );
    },
    disabledEndDate(date) {
      return (
        this.date_debut &&
        moment(date, 'YYYY-MM-DD').add(1, 'days') <
          new Date(moment(this.date_debut, 'DD-MM-YYYY').format('YYYY-MM-DD'))
      );
    },
    handleAvoirFacture(id) {},
    async handleShowFacture(id) {
      this.$refs['visualisationFactureModal'].show();
      const response = await this.displayFact(id);
      if (response.success == true) {
        this.factureToShow = response.response;
      }
    },
    downloadFacture(id) {
      let bodyFormData = new FormData();
      bodyFormData.append('ids[0]', id);
      this.downloadPDF(bodyFormData);
    },
    pagination(paginate) {
      this.page = paginate;
      this.handleFilter();
    },
    changePerPage() {
      this.page = 1;
      this.handleFilter();
    },
    handleFilter() {
      this.checkAll = false;
      this.setLocalStorageFactureMaster2();
      this.getFacturesMaster2({
        page: this.page,
        per_page: this.perPage,
        famille: this.famille,
        order: 'DESC',
        column: 'id',
        grouped: true,
        vendeur_id: this.vendeur,
        acheteur_id: this.acheteur,
        etat: this.statut,
        statut_client: this.statut_client,
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        search: this.searchValue,
        nums: this.nums
      });
    },
    reloadData() {
      this.checkAll = false;
      this.handleFilter();
    },
    changeSearchValue(value) {
      this.searchValue = value;
      this.handleFilter();
      this.setLocalStorageFactureMaster2();
    },
    uploadAvoirSelected() {
      this.$confirm(
        '',
        'Êtes-vous sûr de vouloir générer des avoirs pour ces factures (avec statut annulé) ? ',
        'warning'
      )
        .then(async () => {
          let data = this.factureMaster2
            .filter(function(item) {
              return item.check == true;
            })
            .map(function(item) {
              return item.id;
            });

          const response = await this.generateAvoir(data);
        })
        .catch(error => {});
    },
    handleDeleteModal(item) {
      this.$confirm(
        '',
        'Êtes-vous sur de vouloir supprimer cette facture ?',
        'warning'
      ).then(async () => {
        const response = await this.deleteFactureComptaRhActions(item.id);
        if (response.succes) {
          this.$alert('', 'Facture supprimer', 'success');
        } else {
          this.$alert('', response.error, 'error');
        }
      });
    },

    setLocalStorageFactureMaster2() {
      localStorage.setItem(
        'frais-th-facture-master-2',
        JSON.stringify({
          date_debut: this.date_debut,
          date_fin: this.date_fin,
          acheteur: this.acheteur,
          vendeur: this.vendeur,
          statut: this.statut,
          statut_client: this.statut_client,
          searchValue: this.searchValue
        })
      );
    },
    hideModal(ref) {
      this.factureToShow = null;
      this.$refs[ref].hide();
    }
  },
  components: {
    filterComponent,
    EditableInput,
    search: () => import('@/views/component/SearchInputComponent.vue'),
    ValidateMultipleFactureMaster2,
    ExportDynamic,
    sendMail: () => import('@/views/component/mail/sendMail.vue'),
    // addFactureMaster2: () => import('./component/addFactureMasterFiliale2.vue'),
    Card: () => import('@/views/component/card.vue'),
    templateFraisMaster2: () => import('./component/templateFraisMaster2'),
    // FactureAvoirMaster2: () => import('./component/FactureAvoirMaster2.vue'),
    transfertFactureMaster2ToLibre: () =>
      import('./component/transfertFactureMasterFiliale2ToLibre.vue'),
    // ValidationMultipleStatutClient,
    reglement: () => import('@/views/component/reglement/reglement.vue'),
    updateReglementModal: () =>
      import('@/views/component/reglement/updateReglementModal.vue'),
    Historique: () => import('@/views/component/Historique.vue'),
    avoirFacture: () => import('@/views/component/avoir/avoirFacture.vue'),
    GenerateFactureMaster: () =>
      import('@/views/ThProject/oblige/component/GenerateFactureMaster.vue'),
    GenerateAvoir: () => import('@/views/component/avoir/GenerateAvoir.vue'),
    InputFileExcelNumDossier: () =>
      import(
        '@/views/ThProject/componentRapportTh/inputFileExcelNumDossier.vue'
      )
  },
  async mounted() {
    // this.getSousObjets({
    //   date_debut: this.date_debut,
    //   date_fin: this.date_fin,
    //   famille: this.famille
    // });
    this.date_fin = moment(new Date()).format('DD-MM-YYYY');
    this.date_debut = moment()
      .subtract(2, 'months')
      .format('DD-MM-YYYY');

    this.getVendeurAcheteurMaster2();
    if (localStorage.getItem('frais-th-facture-master-2')) {
      var localStor = JSON.parse(
        localStorage.getItem('frais-th-facture-master-2')
      );
      this.searchValue = localStor.searchValue;
      this.statut = localStor.statut ? localStor.statut : [];
      this.statut_client = localStor.statut_client
        ? localStor.statut_client
        : [];
      this.acheteur = localStor.acheteur;
      this.vendeur = localStor.vendeur;
    } else {
      this.setLocalStorageFactureMaster2();
    }
    this.getSettingFilialeTh({ type: 'master filiale' });

    this.handleFilter();
  }
};
</script>
<style scoped lang="scss">
.master-filiale-new-interface {
  padding-top: 20px;
  padding-left: 15px;
  width: 99%;
  .entete {
    padding: 5px 10px;
  }
  .body-box-rapport {
    height: calc(100vh - 75px);
    width: 100%;
    .table-factures-mensuel-style {
      margin-bottom: 0px;
    }
    .style-somme-th-ttc {
      font-family: 'Montserrat', sans-serif;
      background-color: #d0e4f5;
      position: absolute;
      left: 1px;
      bottom: 0px;
      width: auto;
      height: 35px;
      border-radius: 30px;
      z-index: 120;
    }
  }
}
</style>
<style lang="scss">
.table-rapport-style .table-factures-mensuel-style {
  .custom-checkbox {
    margin-right: 0px;
  }
  tr.b-table-details > td {
    padding: 0px;
  }
  td {
    width: 100px;
    min-width: 100px;
    line-height: 10px;
    span {
      cursor: pointer;
      display: table-row;
      vertical-align: inherit;
    }
  }
  td,
  th {
    width: 135px;
    min-width: 135px;
    border-left: 1px solid white;
    border-right: 0px solid !important;
  }
  .col-plus-obligee {
    z-index: 7;
    min-width: 42px !important;
    width: 42px !important;
  }
  .col-check-obligee {
    min-width: 42px !important;
    width: 43px !important;
    z-index: 9;
  }
  .th-check-obligee {
    z-index: 10 !important;
  }
  .th-plus-obligee {
    z-index: 11 !important;
    width: 43px !important;
    min-width: 43px !important;
  }
  .num-lot-th {
    z-index: 7 !important;
  }
  td,
  th {
    font-size: 9px;
  }
  th {
    padding: 6px 2px;
    width: 100px;
    min-width: 100px;
  }
  .col-plus-obligee {
    z-index: 7;
    min-width: 42px !important;
    width: 42px !important;
  }
  .col-check-obligee {
    min-width: 42px !important;
    width: 43px !important;
    z-index: 9;
  }
  .th-check-obligee {
    z-index: 10 !important;
  }
  .th-plus-obligee {
    z-index: 11 !important;
    width: 43px !important;
    min-width: 43px !important;
  }
  .num-lot-th {
    z-index: 7 !important;
  }
  .background-week {
    justify-content: start !important;
    background-color: #e0e0eb;
    vertical-align: middle;
    td {
      width: 135px;
      min-width: 135px;
      padding: 0px;
      margin: 0px;
      font-size: 9.5px;
      padding-top: 7px;
      padding-bottom: 7px;
    }
    .icon-info-show {
      color: #e0e0eb !important;
    }
    .col-plus-obligee,
    .num-lot-td,
    .col-check-obligee,
    .td-total-coef-oblige {
      background-color: #e0e0eb;
      z-index: 6;
    }
    .num-lot-td {
      display: grid;
      grid-template-columns: 20% 80%;
    }
    .item-details-style {
      border: 1px solid #fff;
      display: table-cell;
    }
  }
  .align-td-item {
    padding: 0px !important;
    .item-details-style {
      justify-content: center;
    }
  }
  .center-th-montant {
    display: flex !important;
    position: relative;
  }
}
</style>
